.contact-us-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  display: none;
  z-index: 100;
  padding: 1rem;
  border: 1px solid #0002;
  border-radius: 5px;
  background: var(--color-bg);
  width: 24rem;
  box-shadow: 1px 1px 10px #0003;
}
.model-bg {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.3s ease-out;
  z-index: 99;
}

.model-bg.shown {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  background: #0004;
  transition: all 0.3s ease-out;
}

.contact-us-container.shown {
  display: block;
  -webkit-animation: contact-popup 1s forwards;
          animation: contact-popup 1s forwards;
  transform: translate(-50%, -50%) scale(1);
}

@-webkit-keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.contact-us-container .contact-us-close {
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 0.25rem;
}

.contact-us-container-inner {
  text-align: center;
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.contact-us-container-inner h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.contact-us-container-inner .contact-us-form {
  display: block;
}

.contact-us-inputs {
  position: relative;
}

.contact-us-form input,
.contact-us-form textarea {
  display: block;
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 0.6rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.contact-us-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
}

.contact-us-form input::-webkit-input-placeholder, .contact-us-form textarea::-webkit-input-placeholder {
  color: transparent;
}

.contact-us-form input::-moz-placeholder, .contact-us-form textarea::-moz-placeholder {
  color: transparent;
}

.contact-us-form input:-ms-input-placeholder, .contact-us-form textarea:-ms-input-placeholder {
  color: transparent;
}

.contact-us-form input::-ms-input-placeholder, .contact-us-form textarea::-ms-input-placeholder {
  color: transparent;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  color: transparent;
}
.contact-us-inputs input:placeholder-shown ~ label,
.contact-us-inputs textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.contact-us-form input:focus ~ label,
.contact-us-form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.contact-us-form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
