body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: var(--font-family);
}

:root {
  /* --font-family: 'Montserrat', sans-serif; */
  --font-family: "Roboto", "Montserrat", sans-serif;
  --color-primary: #dd1c4a;
  --color-primary-900: #5e0d0d;
  --color-primary-900: #700a0a;
  --color-acsent: #f99e1b;
  --color-bg: #fff;
  --color-text: #000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", "Montserrat", sans-serif;
  font-family: var(--font-family);
  scroll-behavior: smooth;
  background: #fff;
  background: var(--color-bg);
  font-size: 16px;
  overflow-x: hidden;
}
a {
  color: unset;
  text-decoration: none;
}

.cta-button {
  display: inline-block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #dd1c4a;
  background: var(--color-primary);
  font-size: 1em;
  font-weight: 600;
  color: #fff !important;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.cta-button:disabled {
  background: #dd1c4a;
  background: var(--color-primary);
  transition: 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.cta-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #700a0a;
  background: var(--color-primary-900);
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.cta-button:hover,
.cta-button:focus {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  transition: 0.3s ease-in-out;
}

.cta-button:hover::before,
.cta-button:focus::before {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.container-title {
  text-align: left;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-bottom: 1.5rem;
  padding-top: 6rem;
}
.container-title h2,
.title-primary {
  color: #700a0a;
  color: var(--color-primary-900);
  font-size: 2.5em;
  line-height: 0.65;
}
.container-title h3 {
  font-size: 1.25em;
  line-height: 1;
  margin-left: auto;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transform: translateX(60%);
          transform: translateX(60%);
}

*,*::before,*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body{
    font-family: var(--font-family);
    scroll-behavior: smooth;
    background: var(--color-bg);
    font-size: 16px;
    overflow-x: hidden;
}
.header-container {
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    position: relative;
}

.main-container .header-container {
    min-height: 200vh !important;
}

.header-container .scroll-down {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 4vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 25px;
}

.main-container .header-container .scroll-down{
    display: block !important;
}


.header-container .scroll-down p{
    margin-top: 0.2rem;
    color: #fff;
    font-weight: 300;
}

.header-container .scroll-down svg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.header-container .header-bg{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    background-image: url(/static/media/EduladeBG.fa6091e1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-container .header-container .header-bg {
    height: 100vh !important;
}


.header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden;
}

.header-content-inner{
    margin-top: 5rem;
}

.header-content h1 {
    font-size: 12rem;
    font-weight: 800;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: rgba(36, 0, 0, 0.3);
    /* text-shadow: 0 0 20px rgba(255, 255, 255, 0.3); */
    letter-spacing: 0px;
    -webkit-text-stroke: 2px #fff;
    line-height: 0.7;
}

.header-content .header-text {
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-shadow: 4px 4px 10px #000;
    /* transform: translateY(-40%); */
}

.main-container .header-content .header-text{
    display: block !important;
}

.header-text h2 {
    font-size: 4rem;
    color: #fff;
    margin-bottom: 0.4rem;
}

.header-text h3 {
    font-size: 1.75rem;
    font-weight: 500;
    color: #fff;
    /* display: inline; */
}

.header-text h3 p {
    display: inline;
    text-decoration: underline;
    color: var(--color-primary);
    text-shadow: 2px 2px 5px #000;
}

.header-text a {
    margin-top: 1.5rem;
}

@media screen and (max-width: 1050px){
    .header-content h1{
        font-size: 9rem;
    }
    .header-text h2{
        font-size: 3rem;
    }
    .header-text h3{
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px){
    .header-content h1{
        font-size: 8rem;
    }
    .header-text h2{
        font-size: 2rem;
    }
    .header-text h3{
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px){
    .header-content h1{
        font-size: 4rem;
    }
    .header-text h2{
        font-size: 2rem;
    }
    .header-text h3{
        font-size: 1rem;
    }
    .header-text{
        -webkit-transform: translateY(-20%);
                transform: translateY(-20%);
    }
}
.what-container {
  /* padding-top: 6rem; */
  max-width: 100vw;
  position: relative;
  padding: 1rem;
}

.what-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 90vw;
  /* height: 1px; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.what-container .what-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.what-container-inner .services-cont {
  /* margin: 0.5rem; */
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100vw;
}

.services-cont .services-item {
  display: flex;
  min-width: 250px;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0.5rem;
  padding: 1rem 0.5rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  /* box-shadow: 2px 2px 16px #0003; */
}

.services-cont .services-item:hover {
  background-color: #0001;
}

.services-cont .service-img {
  margin: auto 0;
}

.services-cont .services-item:nth-child(even) {
  flex-direction: row-reverse;
}

.services-cont .services-item img {
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.services-cont .services-item .service-content {
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
}

.service-title {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 400;
  /* color: var(--color-primary-900); */
}

.services-item .service-content .service-discription {
  width: 100%;
  /* text-transform: lowercase; */
  font-style: italic;
  font-weight: 300;
}

@media screen and (max-width: 1240px) {
  .services-cont .services-item .service-content {
    max-width: 350px !important;
  }

  .services-cont {
    border: none !important;
  }
}

@media screen and (max-width: 1240px) {
  .what-container .what-container-inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 430px) {
  .what-container .what-container-inner .services-item {
    flex-direction: column;
    text-align: center;
  }
  .what-container .what-container-inner .services-item .service-img {
    margin: auto;
  }
  .what-container .what-container-inner .service-title {
    font-size: 1.25rem;
  }
}

.work-container {
  width: 100vw;
  /* padding-top: 6rem */
  /* overflow: hidden; */
}

.flickity-page-dots .dot {
  background: #fff !important;
    opacity: 0.5 !important;
}

.flickity-page-dots .dot.is-selected{
    opacity: 1 !important;
}

.work-container-inner {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.3rem;
  background: var(--color-primary-900);
}

.work-main-carousel {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  /* display: flex;
    padding: 1rem;
    justify-content: flex-start;
    align-items: flex-start; */
  overflow-y: scroll;
  padding: 2rem 1rem;
}

.work-main-carousel::-webkit-scrollbar {
  height: 10px;
  width: 0;
  cursor: pointer;
}

/* Track */
.work-main-carousel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
}

/* Handle */
.work-main-carousel::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161);
  cursor: pointer;
}

/* Handle on hover */
.work-main-carousel::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.work-main-carousel a {
  margin: 0.5rem;
}

.work-main-carousel .carousel-cell {
  position: relative;
  align-self: center;
  display: block;
  margin: 0.25rem;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px #400000;
}

.work-container .carousel-cell img {
  height: 200px;
  min-width: 400px;
  object-fit: fill;
  display: block;
}

.work-container .carousel-cell .work-info {
  padding: 0.75rem;
  width: 100%;
  height: calc(100% - 45px);
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, calc(100% - 49px));
          transform: translate(-50%, calc(100% - 49px));
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  transition: all 0.7s ease-in-out;
}

.carousel-cell h3 {
  font-size: 25px;
  margin: 0.1rem 0;
  border-bottom: 2px solid rgba(59, 59, 59, 0.267);
}

.work-main-carousel a:hover .work-info,
.work-main-carousel a:focus-within .work-info {
  height: 100%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.carousel-cell p {
  font-weight: 300;
  padding-top: 0.2rem;
  font-size: 0.8rem;
}

.work-container .our-work-btn {
  margin: 1rem auto;
  display: block !important;
  transition: all 0.3s ease-in-out;
}

.work-container .our-work-btn:hover,
.work-container .our-work-btn:focus {
  box-shadow: 0px 2px 5px #000;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.work-container .cta-button:hover::before {
  background: var(--color-primary) !important;
}

.loader{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  margin: auto;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
}

/* .about-container{
    min-height: 80vh;
} */

.about-container-inner{
    min-height: 60vh;
    height: 100%;
    background: url(/static/media/AboutUsBG.747183e5.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.about-container-inner p{
    font-size: 1.5rem;
    /* margin: auto; */
    max-width: 1000px;
    padding: .5rem;
    margin-bottom: 2rem;
    color: #fff;
    text-align: justify;
}

@media screen and (max-width: 768px){
    .about-container-inner p{
        font-size: 1rem;
    }
}
.hiring-box {
  width: 100%;
  padding: 5rem 0;
  padding-bottom: 0;
}

.hiring-box-inner {
  width: 500px;
  max-width: 95vw;
  min-height: 300px;
  padding: 2rem;
  background: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

.hiring-box-inner h2 {
  font-size: 2.5rem;
  margin: 1rem;
}

.hiring-box-inner .hiring-btn {
  margin-top: 2rem;
  /* background: var(--color-primary-900); */
  border: 2px solid white;
  transition: all 0.7s ease;
}
.hiring-box-inner .hiring-btn:hover,
.hiring-box-inner .hiring-btn:focus {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  box-shadow: 0px 2px 10px #000;
}

.locate-container {
  max-width: 100vw;
}

.locate-container-inner {
  width: 90%;
  margin: auto;
  padding: 2rem 0;
}

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 50px; */
  z-index: 50;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.navbar-container .navbar-container-inner {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
.navbar-container .brand-logo {
  height: 50px;
  max-height: 100%;
  align-self: flex-start;
  border-radius: 5px;
  margin: 0;
  overflow: hidden;
}

.navbar-container .brand-logo img {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.navbar-container .navbar-items-container {
  display: flex;
  padding: 0.5rem;
  color: #fff;
  flex-wrap: wrap;
}

.navbar-container .navbar-items-container a,
.navbar-container .navbar-items-container li {
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.4rem;
  margin: 0 0.3rem;
  color: inherit;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.partners-container a {
  margin: 0.2rem 0.3rem !important;
  border-bottom: 2px solid #fff7;
}

.navbar-container .navbar-items-container a:hover,
.navbar-container .navbar-items-container a:focus,
.navbar-container .navbar-items-container li:hover,
.navbar-container .navbar-items-container li:focus-within {
  background-color: var(--color-primary-900);
  color: #fff !important;
  transition: all 0.3s ease-in-out;
}

.navbar-container .navbar-items-container a.cta-button {
  margin: 0 0rem 0 2rem;
}

.navbar-toggle {
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px !important;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: none;
  outline: none;
  display: none !important;
}

.navbar-toggle.show::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle.show span {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle.show::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle:before {
  content: "";
  top: 6px;
}

.navbar-toggle span {
  top: 15px;
}

.navbar-toggle:after {
  content: "";
  top: 24px;
}

.navbar-toggle:before,
.navbar-toggle span,
.navbar-toggle:after {
  width: 25px;
  height: 2px;
  display: block;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  position: absolute;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.navbar-mobile {
  flex-direction: column;
  height: 100vh;
  position: fixed;
  max-width: 60%;
  top: 66px;
  right: 0;
  background: var(--color-bg);
  color: var(--color-text) !important;
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  transition: all 1s ease-in-out;
  box-shadow: none;
}

.navbar-mobile.show {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 1s ease-in-out;
  box-shadow: -5px 0 60px 0px rgb(0 0 0 / 40%);
}

.navbar-mobile a,
.navbar-mobile li {
  margin: 0.4rem 2rem !important;
  margin-right: 4rem !important;
}

.navbar-mobile a.cta-button {
  margin: 0 1rem 0 1rem;
}

.navbar-mobile .partners-container {
  position: relative !important;
  height: auto;
  top: 0;
  height: 0;
}

.navbar-mobile li a {
  color: #fff !important;
}

.partners-container {
  position: absolute;
  top: 50px;
  left: 0;
  width: -webkit-max-content;
  width: max-content;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); 
  border-radius: 0 0 5px 5px;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease-out;
}

li.navbar-item:hover .partners-container,
li.navbar-item:focus-within .partners-container {
  height: 220px !important;
  padding: 0.5rem !important;
  color: #fff9;
}

@media screen and (max-width: 920px) {
  .navbar-toggle {
    display: block !important;
  }
  .navbar-pc {
    display: none !important;
  }
  .navbar-container-inner {
    padding: 0 4rem !important;
  }
}

@media screen and (max-width: 550px) {
  .navbar-container-inner {
    padding: 0 3rem !important;
  }
}

.footer-container{
    width: 100%;
    max-width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 300px;
    overflow: hidden;
    background: var(--color-primary);
    margin-top: 5rem;
    color: #fff;
    text-align: center;
    padding-bottom: 0.5rem;
}

.footer-container>p{
    font-size: 0.8em;
}

.footer-container-inner{
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.footer-item{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    padding: 1rem;
}

.footer-container .brand-logo{
    width: 250px;
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.brand-logo img{
    width: 100%;
    border-radius: 10px;
}
.connect > h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-align: left;
}
.connect .socials-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.social-icons{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
    background: #fff;
    color: blue;
    text-align: center;
    line-height: 35px;
    transition: all 0.5s ease-in-out;
}

.social-icons:hover,
.social-icons:focus{
    background: blue;
    color: #fff;
    transition: all 0.5s ease-in-out;
}

.footer-item .footer-link{
    padding: 0.5rem 0.75rem;
    margin: 0.25rem 0;
    display: block;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
}
.footer-item .footer-link:hover,
.footer-item .footer-link:focus{
    background: var(--color-primary-900) !important;
    transition: all 0.5s ease-in-out;
}

.footer-title{
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.footer-item .contact-item{
    display: flex;
    align-items: center;
    margin: 1.25rem .25rem;
}

.footer-item .contact-item:hover > .social-icons,
.footer-item .contact-item:focus-within  > .social-icons{
    background: blue;
    color: #fff;
    transition: all 0.7s ease-in-out;
}

.footer-item .contact-item .social-icons{
    margin: 0 .5rem;
}
.hiring-container {
  overflow: hidden;
}
.no-hiring-data {
  text-align: center;
  font-size: 1.5rem;
}
.hiring-container-inner {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
}

.job-item {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  overflow: hidden;
}

.job-item .job-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #0002;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
  box-shadow: 1px 1px 2px #0003;
}

.job-item .job-container .job-container-inner {
  display: block;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
}

.job-container-inner .job-title {
  display: flex;
  line-height: 1;
}

.job-container-inner .job-title svg {
  margin: auto 0.25rem;
}

.job-container-inner .job-title h3 {
  color: var(--color-primary-900);
  font-size: 1.25em;
  margin: 0 1rem 0rem 0;
}

.job-container-inner .job-title p {
  font-style: italic;
  font-size: 0.75em;
  align-self: center;
}

.job-container-inner .job-tech {
  margin-top: 0.25rem;
  padding-left: 1.25rem;
  font-size: 0.9em;
}

.job-tech .job-tech-tag {
  text-decoration: underline;
  display: inline-block;
  padding: 0.1rem 0.2rem;
  background: #0002;
  margin: 0.1rem;
  border-radius: 2px;
  font-style: italic;
}

.job-caption {
  font-size: 0.7em;
  text-align: center;
  margin-top: 0.25rem;
}

.job-item .job-discription {
  border: 0px solid #0002;
  border-radius: 0px 0px 5px 5px;
  padding: 0rem;
  height: 0;
  overflow: hidden;
  -webkit-transform: translate(0, -3px);
          transform: translate(0, -3px);
  z-index: -1;
  transition: all 0.7s ease;
  font-size: 0rem;
}
.job-discription a {
  color: blue;
  text-decoration: underline;
}

/* .job-item:hover .job-discription,
.job-item:focus-within .job-discription {
  overflow: visible;
  height: 100%;
  border: 1px solid #0002;
  border-top: 0px;
  transition: all 0.7s ease;
  font-size: 1rem;
  padding: 1.5rem;
} */

.job-item .job-discription p:first-child {
  font-size: 1.2em;
}

.job-item .job-discription p:last-child {
  padding-left: 0.75rem;
  font-size: 0.9em;
}
.job-item input {
  display: none;
}
.job-item input:checked ~ .job-discription {
  overflow: visible;
  height: 100%;
  border: 1px solid #0002;
  border-top: 0px;
  transition: all 0.7s ease;
  font-size: 1rem;
  padding: 1.5rem;
}

.job-item input:checked + label {
  background: rgba(114, 114, 114, 0.2);
}

.job-apply-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.8);
          transform: translate(-50%, -50%) scale(0.8);
  display: none;
  z-index: 100;
  padding: 1rem;
  border: 1px solid #0002;
  border-radius: 5px;
  background: var(--color-bg);
  width: 24rem;
  box-shadow: 1px 1px 10px #0003;
}

.model-bg {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.3s ease-out;
  z-index: 99;
}

.model-bg.shown {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  background: #0004;
  transition: all 0.3s ease-out;
}

.job-apply-container.shown {
  display: block;
  -webkit-animation: contact-popup 1s forwards;
          animation: contact-popup 1s forwards;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

@-webkit-keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

.job-apply-container .job-apply-close {
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 0.25rem;
}

.job-apply-container-inner {
  text-align: center;
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.job-apply-container-inner h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.job-apply-container-inner .job-apply-form {
  display: block;
}

.job-apply-inputs {
  position: relative;
}

.job-apply-form input,
.job-apply-form textarea {
  display: block;
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 0.6rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.job-apply-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
}

.job-apply-form input::-webkit-input-placeholder, .job-apply-form textarea::-webkit-input-placeholder {
  color: transparent;
}

.job-apply-form input:-ms-input-placeholder, .job-apply-form textarea:-ms-input-placeholder {
  color: transparent;
}

.job-apply-form input::placeholder,
.job-apply-form textarea::placeholder {
  color: transparent;
}
.job-apply-inputs input:-ms-input-placeholder ~ label, .job-apply-inputs textarea:-ms-input-placeholder ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}
.job-apply-inputs input:placeholder-shown ~ label,
.job-apply-inputs textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.job-apply-form input:focus ~ label,
.job-apply-form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.job-apply-form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.notfound-container {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.back-number {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 14rem;
  z-index: -1;
  opacity: 0.2;
}

.front-content{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.notfound-container p{
  margin-bottom: 2.5rem;
}
.scroll-top-container {
  position: fixed;
  right: 0;
  bottom: 5vh;
  overflow: hidden;
  padding: 0.5rem;
  padding-right: 0;
}

.scroll-top-container-inner {
  cursor: pointer;
  display: block;
  background: var(--color-primary-900);
  color: #fff;
  padding: 0.75rem 1rem;
  /* padding-right: 2rem; */
  border-radius: 5px 0px 0 5px;
  box-shadow: -1px 1px 4px 0px #fff;
  font-size: 1.75rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform: translateX(200%);
          transform: translateX(200%);
  transition: all 0.7s ease-in-out;
}

.scroll-top-container-inner.active{
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 0.7s ease-in-out;
}
.contact-us-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.8);
          transform: translate(-50%, -50%) scale(0.8);
  display: none;
  z-index: 100;
  padding: 1rem;
  border: 1px solid #0002;
  border-radius: 5px;
  background: var(--color-bg);
  width: 24rem;
  box-shadow: 1px 1px 10px #0003;
}
.model-bg {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.3s ease-out;
  z-index: 99;
}

.model-bg.shown {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  background: #0004;
  transition: all 0.3s ease-out;
}

.contact-us-container.shown {
  display: block;
  -webkit-animation: contact-popup 1s forwards;
          animation: contact-popup 1s forwards;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

@-webkit-keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes contact-popup {
  0% {
    display: block;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

.contact-us-container .contact-us-close {
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 0.25rem;
}

.contact-us-container-inner {
  text-align: center;
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.contact-us-container-inner h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.contact-us-container-inner .contact-us-form {
  display: block;
}

.contact-us-inputs {
  position: relative;
}

.contact-us-form input,
.contact-us-form textarea {
  display: block;
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 0.6rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.contact-us-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
}

.contact-us-form input::-webkit-input-placeholder, .contact-us-form textarea::-webkit-input-placeholder {
  color: transparent;
}

.contact-us-form input:-ms-input-placeholder, .contact-us-form textarea:-ms-input-placeholder {
  color: transparent;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  color: transparent;
}
.contact-us-inputs input:-ms-input-placeholder ~ label, .contact-us-inputs textarea:-ms-input-placeholder ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}
.contact-us-inputs input:placeholder-shown ~ label,
.contact-us-inputs textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.contact-us-form input:focus ~ label,
.contact-us-form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.contact-us-form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.case-studies-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.case-studies-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.case-studies-container-inner .case-studies-item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem;
  text-align: center;
  /* border: 1px solid #0002; */
  border-radius: 5px;
  overflow: hidden;
  width: 400px;
  min-height: 250px;
  box-shadow: 1px 1px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.case-studies-container-inner .case-studies-item:hover {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.case-studies-item img {
  height: 200px;
  object-fit: cover;
}

.case-studies-item h2 {
  font-size: 1.5em;
}

.case-studies-item p {
  font-size: 0.7em;
  background: #0002;
}

/* case studies popup Starts */

.case-study-popup-close {
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.case-study-popup-container {
  display: none;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0007;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation: case-study-popup 0.3s ease-out forwards;
          animation: case-study-popup 0.3s ease-out forwards;
  overflow-y: auto;
}
.case-study-popup-container.active {
  display: block;
  opacity: 1;
}

@-webkit-keyframes case-study-popup {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes case-study-popup {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.case-study-popup-container-inner {
  z-index: 102;
  position: fixed;
  top: 80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 1rem;
  margin: 0.5rem;
  background: var(--color-bg);
  border-radius: 5px;
  /* min-width: 25rem; */
  max-width: 100vw;
  min-height: 35rem;
  box-shadow: 1px 1px 10px 2px #0006;
}

.case-study-popup-content {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.case-study-popup-content h2 {
  text-align: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #0003;
}

.case-study-popup-content h3 {
  font-size: 1em;
  width: -webkit-max-content;
  width: max-content;
}

.case-study-popup-content p {
  font-size: 0.8em;
  margin-left: 2rem;
  line-height: 1.5;
}
.case-study-popup-content p a {
  text-decoration: underline;
  font-style: italic;
  color: blue;
}

@media screen and (max-width: 768px) {
  .case-study-popup-container-inner {
    left: 0;
    -webkit-transform: none;
            transform: none;
  }
}

/* case studies popup Ends*/

.admin-case-studies-contianer .forms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.admin-case-studies-contianer form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30rem;
  max-width: 98vw;
  margin: 2rem;
  text-align: center;
  border: 1px solid #0002;
  border-radius: 5px;
  padding: 1rem;
}
.admin-case-studies-contianer form img {
  width: 400px;
  height: 200px;
}
.admin-case-studies-contianer form input,
.admin-case-studies-contianer form textarea {
  width: 98%;
  display: block;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.admin-case-studies-inputs {
  position: relative;
}

.admin-case-studies-inputs-select {
  margin: 0.5rem;
  text-align: left;
}

.admin-case-studies-inputs-select label {
  margin-right: 1rem;
}

.admin-case-studies-inputs-select select {
  width: 100%;
  display: block;
  margin: 1rem 0rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.admin-case-studies-contianer form .admin-case-studies-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
  pointer-events: none;
}

.admin-case-studies-contianer form input::-webkit-input-placeholder,
.admin-case-studies-contianer form textarea::-webkit-input-placeholder {
  color: transparent;
}

.admin-case-studies-contianer form input:-ms-input-placeholder,
.admin-case-studies-contianer form textarea:-ms-input-placeholder {
  color: transparent;
}

.admin-case-studies-contianer form input::-webkit-input-placeholder, .admin-case-studies-contianer form textarea::-webkit-input-placeholder {
  color: transparent;
}

.admin-case-studies-contianer form input:-ms-input-placeholder, .admin-case-studies-contianer form textarea:-ms-input-placeholder {
  color: transparent;
}

.admin-case-studies-contianer form input::placeholder,
.admin-case-studies-contianer form textarea::placeholder {
  color: transparent;
}
.admin-case-studies-contianer form input:-ms-input-placeholder ~ label, .admin-case-studies-contianer form textarea:-ms-input-placeholder ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}
.admin-case-studies-contianer form input:placeholder-shown ~ label,
.admin-case-studies-contianer form textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.admin-case-studies-contianer form input:focus ~ label,
.admin-case-studies-contianer form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.admin-case-studies-contianer form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.admin-case-studies-contianer .upload-disclaimer {
  font-size: 0.7em;
  font-weight: 200;
}

.admin-case-studies-contianer .upload-disclaimer a {
  color: blue;
}

.filepond--credits {
  display: none;
}

@media screen and (max-width: 768px) {
  .admin-case-studies-contianer form {
    min-width: 0 !important;
  }
}

.admin-hirings-contianer .forms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.admin-hirings-contianer form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30rem;
  max-width: 98vw;
  margin: 2rem;
  text-align: center;
  border: 1px solid #0002;
  border-radius: 5px;
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  .admin-hirings-contianer form {
    min-width: 0 !important;
  }
}

.admin-hirings-contianer form input,
.admin-hirings-contianer form textarea {
  width: 98%;
  display: block;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.admin-hirings-inputs-select {
  margin: 0.5rem;
  text-align: left;
}

.admin-hirings-inputs-select select {
  width: 101%;
  display: block;
  margin: 1rem 0rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.admin-hirings-contianer form .admin-hirings-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
  pointer-events: none;
}

.admin-hirings-inputs {
  position: relative;
}

.admin-hirings-contianer form .admin-hirings-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
}

.admin-hirings-contianer form input::-webkit-input-placeholder,
.admin-hirings-contianer form textarea::-webkit-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input:-ms-input-placeholder,
.admin-hirings-contianer form textarea:-ms-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input::-webkit-input-placeholder, .admin-hirings-contianer form textarea::-webkit-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input:-ms-input-placeholder, .admin-hirings-contianer form textarea:-ms-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input::placeholder,
.admin-hirings-contianer form textarea::placeholder {
  color: transparent;
}
.admin-hirings-contianer form input:-ms-input-placeholder ~ label, .admin-hirings-contianer form textarea:-ms-input-placeholder ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}
.admin-hirings-contianer form input:placeholder-shown ~ label,
.admin-hirings-contianer form textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.admin-hirings-contianer form input:focus ~ label,
.admin-hirings-contianer form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.admin-hirings-contianer form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.admin-hirings-contianer .upload-disclaimer {
  font-size: 0.7em;
  font-weight: 200;
}

.admin-hirings-contianer .upload-disclaimer a {
  color: blue;
}

.filepond--credits {
  display: none;
}

.admin-container {
  width: 100%;
}
.admin-container-inner {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.admin-items{
  padding: 2rem;
  margin: 1rem;
  width: 10rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #0002;
  text-align: center;
  border-radius: 5px;
}
.services-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.services-container-inner {
  max-width: 1000px;
  margin: 2rem auto;
  text-align: center;
}

.service-img {
  max-width: 15rem;
  margin: 1rem auto;
}

.service-title {
  text-align: center;
  font-weight: 600;
}

.services-container-inner .service-discription {
  font-weight: 300;
}

.service-details a {
  text-decoration: underline;
  color: blue;
}

