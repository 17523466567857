.hiring-container {
  overflow: hidden;
}
.no-hiring-data {
  text-align: center;
  font-size: 1.5rem;
}
.hiring-container-inner {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
}

.job-item {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  overflow: hidden;
}

.job-item .job-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #0002;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
  box-shadow: 1px 1px 2px #0003;
}

.job-item .job-container .job-container-inner {
  display: block;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
}

.job-container-inner .job-title {
  display: flex;
  line-height: 1;
}

.job-container-inner .job-title svg {
  margin: auto 0.25rem;
}

.job-container-inner .job-title h3 {
  color: var(--color-primary-900);
  font-size: 1.25em;
  margin: 0 1rem 0rem 0;
}

.job-container-inner .job-title p {
  font-style: italic;
  font-size: 0.75em;
  align-self: center;
}

.job-container-inner .job-tech {
  margin-top: 0.25rem;
  padding-left: 1.25rem;
  font-size: 0.9em;
}

.job-tech .job-tech-tag {
  text-decoration: underline;
  display: inline-block;
  padding: 0.1rem 0.2rem;
  background: #0002;
  margin: 0.1rem;
  border-radius: 2px;
  font-style: italic;
}

.job-caption {
  font-size: 0.7em;
  text-align: center;
  margin-top: 0.25rem;
}

.job-item .job-discription {
  border: 0px solid #0002;
  border-radius: 0px 0px 5px 5px;
  padding: 0rem;
  height: 0;
  overflow: hidden;
  transform: translate(0, -3px);
  z-index: -1;
  transition: all 0.7s ease;
  font-size: 0rem;
}
.job-discription a {
  color: blue;
  text-decoration: underline;
}

/* .job-item:hover .job-discription,
.job-item:focus-within .job-discription {
  overflow: visible;
  height: 100%;
  border: 1px solid #0002;
  border-top: 0px;
  transition: all 0.7s ease;
  font-size: 1rem;
  padding: 1.5rem;
} */

.job-item .job-discription p:first-child {
  font-size: 1.2em;
}

.job-item .job-discription p:last-child {
  padding-left: 0.75rem;
  font-size: 0.9em;
}
.job-item input {
  display: none;
}
.job-item input:checked ~ .job-discription {
  overflow: visible;
  height: 100%;
  border: 1px solid #0002;
  border-top: 0px;
  transition: all 0.7s ease;
  font-size: 1rem;
  padding: 1.5rem;
}

.job-item input:checked + label {
  background: rgba(114, 114, 114, 0.2);
}
