.header-container {
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    position: relative;
}

.main-container .header-container {
    min-height: 200vh !important;
}

.header-container .scroll-down {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 4vh;
    transform: translateX(-50%);
    height: 25px;
}

.main-container .header-container .scroll-down{
    display: block !important;
}


.header-container .scroll-down p{
    margin-top: 0.2rem;
    color: #fff;
    font-weight: 300;
}

.header-container .scroll-down svg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.header-container .header-bg{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    background-image: url(../../assets/img/EduladeBG.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-container .header-container .header-bg {
    height: 100vh !important;
}


.header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden;
}

.header-content-inner{
    margin-top: 5rem;
}

.header-content h1 {
    font-size: 12rem;
    font-weight: 800;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: rgba(36, 0, 0, 0.3);
    /* text-shadow: 0 0 20px rgba(255, 255, 255, 0.3); */
    letter-spacing: 0px;
    -webkit-text-stroke: 2px #fff;
    line-height: 0.7;
}

.header-content .header-text {
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-shadow: 4px 4px 10px #000;
    /* transform: translateY(-40%); */
}

.main-container .header-content .header-text{
    display: block !important;
}

.header-text h2 {
    font-size: 4rem;
    color: #fff;
    margin-bottom: 0.4rem;
}

.header-text h3 {
    font-size: 1.75rem;
    font-weight: 500;
    color: #fff;
    /* display: inline; */
}

.header-text h3 p {
    display: inline;
    text-decoration: underline;
    color: var(--color-primary);
    text-shadow: 2px 2px 5px #000;
}

.header-text a {
    margin-top: 1.5rem;
}

@media screen and (max-width: 1050px){
    .header-content h1{
        font-size: 9rem;
    }
    .header-text h2{
        font-size: 3rem;
    }
    .header-text h3{
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px){
    .header-content h1{
        font-size: 8rem;
    }
    .header-text h2{
        font-size: 2rem;
    }
    .header-text h3{
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px){
    .header-content h1{
        font-size: 4rem;
    }
    .header-text h2{
        font-size: 2rem;
    }
    .header-text h3{
        font-size: 1rem;
    }
    .header-text{
        transform: translateY(-20%);
    }
}