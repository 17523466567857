/* .about-container{
    min-height: 80vh;
} */

.about-container-inner{
    min-height: 60vh;
    height: 100%;
    background: url(../../assets/img/AboutUsBG.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.about-container-inner p{
    font-size: 1.5rem;
    /* margin: auto; */
    max-width: 1000px;
    padding: .5rem;
    margin-bottom: 2rem;
    color: #fff;
    text-align: justify;
}

@media screen and (max-width: 768px){
    .about-container-inner p{
        font-size: 1rem;
    }
}