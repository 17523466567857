.hiring-box {
  width: 100%;
  padding: 5rem 0;
  padding-bottom: 0;
}

.hiring-box-inner {
  width: 500px;
  max-width: 95vw;
  min-height: 300px;
  padding: 2rem;
  background: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

.hiring-box-inner h2 {
  font-size: 2.5rem;
  margin: 1rem;
}

.hiring-box-inner .hiring-btn {
  margin-top: 2rem;
  /* background: var(--color-primary-900); */
  border: 2px solid white;
  transition: all 0.7s ease;
}
.hiring-box-inner .hiring-btn:hover,
.hiring-box-inner .hiring-btn:focus {
  transform: scale(1.08);
  box-shadow: 0px 2px 10px #000;
}
